let infopanelSticky = document.querySelector(".header-7days__infopanel-sticky");
let recommendationElement = document.querySelector('.header-7days__recommendation_box');

if (recommendationElement) {
    window.addEventListener("scroll", function () {
        let scrollSticky = recommendationElement.offsetTop;
        if (window.scrollY > scrollSticky) {
            infopanelSticky.style.display = 'block';
        } else {
            infopanelSticky.style.display = 'none';
        }
    });
}
$('.header-7days__recommendation_box-slider').slick({
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    dots: false,
    autoplay: false,

    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow:4,
                dots: false,
                autoplay: false,
                arrows: false,

            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow:3,
                dots: false,
                autoplay: false,
                arrows: false,

            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow:2,
                dots: false,
                autoplay: false,
                arrows: false,

            }
        },

    ]
});
