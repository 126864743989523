$( ".material-7days__tags_item.click-full" ).click(function() {
$(".material-7days__tags_full-box").stop().fadeToggle();
});



$('.material-7days__slider').slick({
    arrows:false,
    slidesToShow:3,
    centerMode: false,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1,
                centerPadding: '40px',
                dots: true,
                autoplay:true,
                arrows:false,
                centerMode: true,
            }
        },

    ]
});





$('.childhood-7days__preview_slider').slick({
    arrows: false,
    slidesToShow: 3,
    centerMode: false,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1,
                centerPadding: '40px',
                dots: true,
                autoplay: true,
                arrows: false,
            }
        },

    ]
});
