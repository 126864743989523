$('.base-material-7days__videobox-slider').slick({
    arrows:true,
    slidesToShow:2,
    centerPadding: '40px',
    centerMode: false,
    autoplay:false,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1,
                centerPadding: '50px',
                dots: true,
                autoplay:true,
                arrows:false,
                centerMode: true,
            }
        },

    ]
});
$('.base-material-7days__horoscope_slider').slick({
    arrows:false,
    slidesToShow:4,

    centerMode: false,
    autoplay:false,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1,

                dots: true,
                autoplay:true,
                arrows:false,

            }
        },

    ]
});





$('.base-material-7days__slider-page').slick({
    arrows:false,
    slidesToShow:4,
    centerMode: false,
    autoplay:false,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1,
                dots: true,
                autoplay:true,
                arrows:false,

            }
        },

    ]
});










$('.base-material-7days__shops_slider').slick({
    arrows:true,
    slidesToShow:7,
    centerPadding: '40px',
    centerMode: false,
    autoplay:false,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1,
                centerPadding: '40px',
                dots: true,
                autoplay:true,
                arrows:false,
                centerMode: true,
            }
        },

    ]
});



$('.base-material-7days__shops_slider-page').slick({
    arrows:true,
    slidesToShow:4,
    centerPadding: '40px',
    centerMode: false,
    autoplay:false,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1,
                centerPadding: '40px',
                dots: true,
                autoplay:true,
                arrows:false,
                centerMode: true,
            }
        },

    ]
});



$('.base-material-7days__materialbox_stars-slider-bottom').slick({
    slidesToScroll: 1,
    slidesToShow: 1,
    dots: true,
    autoplay:true,
    arrows:false,

});

